//
//
// user.scss
//
// Place your own custom CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.solid-white {
  background-color: #fff !important;
}

.small-lead {
  font-size: 1.1rem;
}

.skew-it {
  transform: skewY(-5deg) !important;
}

.smart-quiz-img {
  max-width: 400px;
}

.screen-width {
  max-width: 95vw;
  margin-inline: auto;
}

.slightly-wider-width {
  max-width: 1300px;
  margin-inline: auto;
}

@media (max-width: 1500px) {
  .product-matrix-image {
    // height: 100%;
    // max-height: 1000px !important;
  }
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.promotion-banner {
  display: none;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 100%;
  height: 80px;
  font-size: 1.5em;
  background: rgb(95, 95, 204);
}

.pb-big {
  padding-bottom: 2em;
}

.pt-big {
  padding-top: 2em;
}

.py-big {
  padding-bottom: 2em;
  padding-top: 2em;
}

.stat-card {
  background-color: #561d6f;
  border: none;
}

.white-stat-card {
  background-color: #fff;
  border: none;
}

.display-4-smaller {
  font-size: 3rem;
  font-weight: 700;
  line-height: 1;
}
@media (max-width: 1200px) {
  .display-4-smaller {
    font-size: calc(1.275rem + 2.7vw);
  }
}
.w-max-content {
  width: max-content;
}

.gap-6 {
  gap: 2rem;
}

.navbar.scrolled {
  top: 80px;
}

.text-balance {
  text-wrap: balance;
}

.crowdcube-bg {
  background-color: #ed7d31;
}

.hero-crowdcube {
  position: relative;
  top: 140px;
  left: 0;
  z-index: 3;
  background-color: #ed7d31;
  text-align: center;
  img {
    margin-bottom: -42px;
    @media (max-width: 1160px) {
      width: 100%;
      height: auto;
    }
  }
  // background: url("../../heros/hero-crowdcube.svg") no-repeat center center;
  .hero-crowdcube-bottom {
    // position: absolute;
    // bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 24px;

    .disclaimer {
      text-transform: uppercase;
      font-weight: normal;
      color: white;
      font-size: 14px;
    }
    button {
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 64px;
      height: 64px;
      min-width: 240px;
      text-transform: uppercase;
      font-size: 24px;
      border: none;
      padding: 12px 24px;
      font-weight: bold;
      @media (max-width: 900px) {
        font-size: 14px;
        height: 24px;
        margin-bottom: 0;
      }
    }
    button a {
      color: #ed7d31;
    }
    .friendshare {
      text-decoration: underline;
      color: white;
      padding: 16px 0;
    }
    .sharearrow {
      height: 16px;
      width: 16px;
      margin-right: 4px;
      display: inline-block;
      line-height: 16px;
    }
  }
}
@media (min-width: 990px) {
  .max-w-500px {
    max-width: 500px;
  }
  div#special-parent-container {
    max-width: none;
    width: 100%;
  }

  div#preview-div-special {
    max-width: 100vw;
    width: 100%;
  }
}

div#preview-div-special {
  max-width: 100vw;
}

.main-graphic-laptops {
  object-fit: contain;
}

.borred {
  border: 1px solid red !important;
}

.timeline {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__item {
    width: 100%;
    display: flex;
    min-height: 100px;
    align-items: stretch;
    text-wrap: balance;
    &:nth-child(odd) {
      flex-direction: row-reverse;
      text-align: right;
    }
  }
  &__item:first-child {
    .timeline__time {
      &::after {
        height: 50%;
        top: auto;
        bottom: 0px;
      }
    }
  }
  &__item:last-child {
    .timeline__time {
      &::after {
        height: 50%;
      }
    }
  }
  &__content {
    flex: 1;
    padding: 1.75rem;
  }

  &__time {
    width: 120px;
    position: relative;
    min-height: 100%;
    display: flex;
    align-items: center;
    font-weight: bold;
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 50%;
      width: 3px;
      height: 100%;
      background-color: #555a6436;
      transform: translateX(-50%);
      z-index: 1;
    }
    div {
      margin: 0 auto;
      width: max-content;
      background-color: #b73bff;
      color: white;
      border-radius: 5rem;
      padding: 6px 12px;
      font-size: 14px;
      text-align: center;
      border: 5px solid white;
      position: relative;
      z-index: 3;
    }
  }
}

@media (max-width: 990px) {
  .timeline {
    &__item {
      &:nth-child(odd) {
        flex-direction: row;
        text-align: left;
      }
    }
    &__content:first-child {
      display: none;
    }
  }
}

#crowdfund-header {
  margin: 24px 0;
  font-size: 30px;
  color: white;
  font-weight: 900;
  text-align: center;
  text-wrap: balance;
  width: max-content;
  max-width: 500px;
}

#pre-reg-button {
  white-space: nowrap;
}

#pre-reg-button > span {
  font-weight: bold;
  font-size: 16px;
}

#divider-inverse {
  transform: rotate(180deg);
  width: 100%;
}

.crowdcube-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1140px;
  padding: 0.5em;
}

.crowdcube-copy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 5px;
}

.crowdcube-copy > h2 {
  font-size: 28px;
  white-space: balance;
}

@media (max-width: 991.98px) {
  .crowdcube-copy > h2 {
    font-size: 26px;
  }
}

.crowdcube-button {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  white-space: nowrap;
  max-width: 300px;
  gap: 6px;
  height: 100%;
}

.crowdcube-button img {
  max-width: 100%;
}

.btn-crowdcube {
  max-height: 100px;
  max-width: 100%;
  height: 100px;
}

.btn-crowdcube > img {
  height: 100px;
}

.btn-crowdcube:hover {
  transform: scale(1.1);
  transition: transform 0.05s ease-in-out;
}

@media (max-width: 790px) {
  .crowdcube-button {
    width: 100%;
    max-width: 200px;
  }
}

.show-on-mob {
  display: none;
}

@media (max-width: 590px) {
  .crowdcube-wrapper {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .crowdcube-button {
    width: 100%;
    max-width: 95vw;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 0px;
  }
  .crowdcube-button > a {
    margin-top: 10px;
  }
  .crowdcube-button > p {
    font-size: 18px;
    color: white;
    white-space: balance;
    margin: 0;
    font-weight: 900;
  }
  .btn-crowdcube:hover {
    transform: none;
    transition: none;
  }
  .btn-crowdcube {
    width: 200px;
  }
  .hide-on-mob {
    display: none;
  }
  .show-on-mob {
    display: block;
  }
}

.crowdcube-copy > p {
  font-size: 18px;
  white-space: balance;
}

@media (min-width: 576px) {
  .container-width {
    max-width: 540px;
    margin-inline: auto;
  }
}
@media (min-width: 768px) {
  .container-width {
    max-width: 720px;
    margin-inline: auto;
  }
}
@media (min-width: 992px) {
  .container-width {
    max-width: 960px;
    margin-inline: auto;
  }
}
@media (min-width: 1200px) {
  .container-width {
    max-width: 1140px;
    margin-inline: auto;
  }
}
@media (min-width: 1200px) {
  .main-container-width {
    max-width: 1240px;
    margin-inline: auto;
  }
}

.crowdcube-copy > * {
  color: white;
  margin: 0;
  font-weight: 900;
}

.crowd-funding-section {
  display: flex;
  flex-direction: column;
  background: rgb(255, 153, 0);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  top: 0;
  width: 100%;
  z-index: 10000;
  .crowdfunding-purple {
    color: rgb(100, 43, 129);
  }
}

.button-prereg {
  margin: 16px 0;
  background-color: #b73bff;
  color: #ffffff;
  border: none;
  line-height: 1.5em;
  font-size: 1em;
  border-radius: 32px;
  padding: 12px 24px;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  &.light {
    background-color: #fff;
    color: #b73bff;
  }
}

/* hack the unwanted x-scroll */
body#crowdcube .row {
  margin-left: 0;
  margin-right: 0;
}

#cards {
  @media (max-width: 420px) {
    .display-4 {
      font-size: 1.6rem;
    }
    .m-4 {
      margin: 1rem 0 !important;
    }
  }
}

.newcrowdcube {
  button {
    margin: 16px 0;
    background-color: #b73bff;
    color: #ffffff;
    border: none;
    line-height: 1.5em;
    font-size: 1em;
    border-radius: 32px;
    padding: 12px 24px;
    font-weight: bold;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
  }
}

.bg-gray {
  background-color: #efefef;
}

.navbar.scrolled.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [stroke]:not([stroke='none']),
.navbar.scrolled.navbar-dark .navbar-toggler svg [stroke]:not([stroke='none']),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [stroke]:not([stroke='none']),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-toggler
  svg
  [stroke]:not([stroke='none']) {
  stroke: #612c6c;
}
.navbar.scrolled.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [fill]:not([fill='none']),
.navbar.scrolled.navbar-dark .navbar-toggler svg [fill]:not([fill='none']),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-brand-dynamic-color
  svg
  [fill]:not([fill='none']),
.navbar.navbar-toggled-show.navbar-dark
  .navbar-toggler
  svg
  [fill]:not([fill='none']) {
  fill: #612c6c;
}

.rounded-corners {
  border-radius: 25px;
}

.separator {
  display: flex;
  align-items: center;
}

.separator .line {
  height: 3px;
  flex: 1;
  background-color: #000;
}

.separator h2 {
  margin: 0;
  padding: 0 2rem;
}

.curriculum-card {
  padding: 1rem;
}

.btn-secondary {
  color: #fff;
  background-color: #39b5c7;
  border-color: #eaedf2;
  box-shadow: none;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #2b8694;
  border-color: #cbd2df;
}
.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none, 0 0 0 0.2rem rgba(206, 209, 214, 0.5);
}
.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #2c3038;
  background-color: #eaedf2;
  border-color: #eaedf2;
}
.btn-secondary:not(:disabled):not(.disabled):active,
.btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #2c3038;
  background-color: #cbd2df;
  border-color: #c3cbda;
}
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(206, 209, 214, 0.5);
}

@media (min-width: 1200px) {
  .navbar-nav > .nav-item {
    font-size: 1rem;
  }
}

.flickity-page-dots .dot {
  width: 10px;
  height: 10px;
  background: #fff;
  opacity: 0.4;
}

hr.left-decoration {
  width: 10%;
  border-top: 4px solid #712c7c;
  margin-right: 100%;
}

hr.left-decoration-white {
  width: 10%;
  border-top: 4px solid #fff;
  margin-right: 100%;
}

hr.full-width {
  width: 100%;
  border-top: 2px solid #2c3038;
  margin: 0;
}

@media (min-width: 992px) {
  [class*='sticky-lg'][class*='-top'][class*='col'] {
    top: 6.5rem;
  }
}

.contact-mail {
  min-width: 48px !important;
  min-height: 48px !important;
}

.contact-linkedin {
  min-width: 26px !important;
  min-height: 26px !important;
}

.nav-item-new::after {
  content: 'new';
  color: #ed7d31;
  font-variant: small-caps;
  position: relative;
  top: 1rem;
  right: 26px;
}

.xxl-arrow {
  min-height: 8rem;
}
